.contact-photo {
    background-image: url('../images/the-girls.png');
    background-size: cover;
    background-position: top left;
    height: 98vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: -1;
}

.overlay-contact {
    height: 98vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0)), 
                linear-gradient(to bottom, rgb(0, 0, 0) 5%, rgba(0, 0, 0, 0) 15%),
                linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0) 60%);
}

.page-content.contact-page {
    margin-top: 200px;
}

.form-row {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    border: 2px outset rgba(78, 78, 78, 0.719);
    box-shadow: 10px 10px 20px rgba(0,0,0,0.7);
    border-radius: 5px;
    padding-bottom: 20px;
}

textarea {
    width: 100%;
    height: 150px;
    font-family: 'Noto Sans', sans-serif;
}

.contact-input, .button-container, button {
    width: 100%;
    border-radius: 5px;
    border: none;
    box-shadow: 5px 10px 20px rgba(0,0,0,0.5);
    font-family: 'Noto Sans', sans-serif;
    margin-top: 5px;
}

.form-col {
    padding-top: 5px;
}

.contact-input {
    height: 35px;
}

.contact-input:focus, textarea:focus {
    background-color: rgb(231, 255, 251);
    border: none;
    box-shadow: 5px 10px 20px rgba(0,0,0,0.8);
}

.check-container {
    margin-top: 5px;
}

input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 2px outset #838383;
    border-radius: 5px;
    margin-right: 15px;
    position: relative;
}

input[type=checkbox]:checked {
    background-color: rgb(158, 0, 53);
}

input[type=checkbox]:checked:before {
    content: '\2713'; /* CSS code for checkmark */
    color: white;
    position: absolute;
    top: -5px;
    left: 2px;
    font-size: 22px;
}

input[type=checkbox]:focus{
    outline: none !important;
}

.label-checkbox {
    vertical-align: 10px;
}

button {
    background-color: rgb(158, 0, 53);
    margin-bottom: 5px;
    height: 45px;
    color: white;
    font-size: 20px;
    transition: all ease-in-out 0.15s;
    cursor: pointer;
}

button:hover {
    transform: translate(0, -1.5px) scale(1.01);
}

.plane-icon {
    text-align: center;
    color: white;
    font-size: 30px;
}

.send-link-wrap {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: all ease-in-out 0.3s;
}

.send-link-wrap:hover, .send-link-wrap:focus {
    transform: translate(0px, -1.5px) scale(1.05);
}


@media only screen and (min-width: 450px) {
    .form-row {
        width: 70%;
    }
}

@media only screen and (min-width: 1000px) {
    .form-row {
        width: 50%;
    }
}