.media {
    position: relative;
}

.background {
    background-image: url('../images/hero.jpg');
    background-size: cover;
    background-position: top;
    height: 98vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: -1;
}

.overlay {
    height: 98vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 0;
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 40%, rgb(0, 0, 0) 80%);
}

.row.iframe-row {
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
}

iframe {
    border-radius: 10px;
    box-shadow: 10px 10px 20px rgba(0,0,0,0.7);
    opacity: 0.9;
    margin-bottom: 15px;
    border: ridge 3px rgb(99, 99, 99);
}