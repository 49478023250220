main, .app {
  margin-top: 0;
  padding-top: 0;
}

.page-content {
  position: absolute;
  margin-top: 260px;
  padding-bottom: 100px;
  z-index: 1;
  width: 100%;
}

.about {
  margin-top: 33vh;
}

.text-box {
  background-color: rgba(0, 0, 0, 0.85);
  border: 2px outset rgba(41, 41, 41, 0.616);
  box-shadow: 10px 10px 20px rgba(0,0,0,0.7);
  border-radius: 5px;
  /* color: black; */
  padding: 20px 30px 20px 30px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.text-box-landing {
  max-width: none;
  background-color: rgba(0, 0, 0, 0.7);
}

.link {
  color: inherit;
}

.text-box-link {
  transition: all 0.25s ease;
}

.text-box-link:hover {
  transform: translate(0, -1.5px) scale(1.03);
  box-shadow: 10px 10px 20px rgba(0,0,0,0.9);
}