nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    padding-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
    height: 150px;
    background: linear-gradient(to bottom, rgb(0, 0, 0) 25%, rgba(0, 0, 0, 0));
}

.signature-logo {
    width: 75%;
    display: inline-block;
    text-align: left;
    transition: all ease-in-out 0.5s;
}

.signature-logo.smaller {
    width: 50%;
}

.signature-logo:hover {
    transform: translate(3px, -3px) scale(1.05);
}

@media only screen and (min-width: 800px) {
    .signature-logo {
        width: 516px;
    }
    
    .signature-logo.smaller {
        width: 230px;
    }
}


.hamburger-container {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    background: black;
    float: right;
    border-radius: 5px;
}

.hamburger-container .toggler {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 3;
}

.hamburger-container .hamburger {
    z-index: 2;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background: transparent;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 4px;
    transition: all .25s ease-in-out;
}
.hamburger-container .toggler:hover + .hamburger,
.hamburger-container .toggler:focus + .hamburger {
    transform: translate(0, -1.5px) scale(1.1);
}

.hamburger-container .hamburger > div {
    position: relative;
    height: 3px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-items: center;
    align-items: center;
    border-radius: 2px;
    transition: all .2s ease-in-out;
}

.hamburger-container .hamburger > div:before,
.hamburger-container .hamburger > div:after {
    content: '';
    position: absolute;
    top: -10px;
    height: 3px;
    width: 100%;
    border-radius: 3px;
    background: inherit;
}

.hamburger-container .hamburger > div:after {
    top: 10px;
}

/* rotate hamburger on click */
.hamburger-container .toggler:checked + .hamburger > div {
    transform: rotate(45deg);
}

/* change hamberger into X shape */
.hamburger-container .toggler:checked + .hamburger > div:before,
.hamburger-container .toggler:checked + .hamburger > div:after {
    top: 0;
    transform: rotate(90deg);
}

/* Show menu */
.hamburger-container .toggler:checked ~ .menu {
    visibility: visible;
}

.hamburger-container .toggler:checked ~ .menu > div {
    transform: scale(1);
    transition-duration: 0.4s;
}

.hamburger-container .toggler:checked ~ .menu > div > div {
    opacity: 1;
    transition: opacity 0.4s ease;
}

.hamburger-container .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.hamburger-container .menu > div {
    background: rgba(0, 0, 0, 0.85);
    border-radius: 50%;
    width: 250vw;
    height: 250vw;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: all 0.4s ease;
}

.hamburger-container .menu > div > div {
    text-align: center;
    max-width: 90vw;
    max-height: 100vh;
    opacity: 0;
    transition: opacity 0.4s ease;
}

.hamburger-container .menu > div > div > ul > li {
    list-style: none;
    color: rgb(255, 255, 255);
    padding: 10px;
    font-weight: bold;
    z-index: 4;
    cursor: pointer;
    transition: all 0.15s ease;
}

.hamburger-container .menu > div > div > ul {
    padding-left: 0;
    margin-left: 0;
}

.hamburger-container .menu > div > div > ul > li:hover,
.hamburger-container .menu > div > div > ul > li:focus {
    transform: translate(0, -1.5px) scale(1.1);
    text-decoration: underline;
}