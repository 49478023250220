footer {
    background-color: rgb(0, 0, 0);
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

#toolTip {
    padding-top: 0;
    padding-bottom: 0;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
}

.icon-wrapper {
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.15s;
    cursor: pointer;
    box-shadow: 0px 3px 15px rgba(255, 255, 255, 0);

}

#facebook {
    background-color: #4267B2;
}

#youtube {
    background-color: rgb(255,0,0);
}

#paypal {
    background-color: #0079C1;
}

#mail {
    background-color: rgb(158, 0, 53);
}

.img-icon {
    width: 100%;
    border-radius: 50%;
}

.awesome-icon {
    font-size: 20px;
    color: white;
    vertical-align: -5px;
}

.icon-wrapper:hover, .icon-wrapper:focus {
    transform: translate(0px, -1.5px);
    /* box-shadow: 0px 3px 15px rgba(255, 255, 255, 1); */
}