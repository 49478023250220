.landing {
    position: relative;
}

.hero {
    background-image: url('../images/homegrown.jpg');
    background-size: cover;
    background-position: center;
    height: 98vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: -1;
}

.overlay {
    height: 98vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 0;
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 40%, rgb(0, 0, 0) 80%);
}

.content {
    position: absolute;
    margin-top: 85vh;
    padding-bottom: 70px;
    z-index: 1;
    width: 100%;
    transition: all ease-in-out .7s;
}

/* .top {
    margin-top: 150px;
} */

.mail-chimp {
    width: 80%;
    max-width: 700px;
    min-width: 250px;
    margin-right: auto;
    margin-left: auto;
}

input {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    font-size: 18px;
    padding-left: 20px;
    font-family: 'Noto Sans', sans-serif;
    margin-bottom: 5px;
}

input:focus{
    background-color: rgb(231, 255, 251);
    border: none;
    box-shadow: 5px 10px 20px rgba(0,0,0,0.8);
}

.msg-alert {
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
}

.welcome-txt {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.welcome-link {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
    display: inline-block;
    transition: all 0.15s ease;
}

.welcome-link:hover {
    transform: translate(0, -1.5px) scale(1.03);

}

.header-box {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.chevron-box {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin-top: -15px;
}

#chevron {
    font-size: 25px;
    transition: all ease-in-out 0.3s;
}

/* @media only screen and (min-width: 640px) {
    .chevron-box {
        display: none;
    }
    .content {
        margin-top: 80vh;
    }
} */

.ad-box {
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
}

.ad-live, .ad-record {
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ad-record {
    background-color: rgb(255, 32, 32);
}

.ad-live {
    background-color: rgb(255, 240, 32);
}

.wave {
    /* width: 50%; */
}

.sk-line {
    width: 100px;
    height: 100px;
}